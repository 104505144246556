import { ClienteEndereco } from './clienteEndereco';

export class Cliente {

    public id: number;
    public empresaId: number;
    public nome: string;
    public email: string;
    public cpf: string;
    public telefone: string;
    public dataCadastro: Date;
    public enderecos: ClienteEndereco[];
    public isSocialLogin:boolean;

}