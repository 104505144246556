import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localept from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
registerLocaleData(localept, 'pt');
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { ClienteCadastroComponent } from './cliente/cadastro/cliente-cadastro/cliente-cadastro.component';
import { CardapioComponent } from './menu/cardapio/cardapio.component';
import { SacolaComponent } from './sacola/sacola/sacola.component';
import { LoginComponent } from './login/login/login.component';
import { PedidoConcluidoComponent } from './sacola/pedido-concluido/pedido-concluido.component';
import { HeaderComponent } from './header/header.component';
import { CalcularTaxaEntregaComponent } from './shared/componentes/calcular-taxa-entrega/calcular-taxa-entrega.component';
import { ContatoComponent } from './contato/contato.component';
import { AvaliacaoComponent } from './avaliacao/avaliacao.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { PedidosComponent } from './pedidos/pedidos.component';
import { ConsultaEnderecosComponent } from './cliente/consulta-enderecos/consulta-enderecos.component';
import { Interceptor } from './shared/interceptor';
import { NotFoundComponent } from './not-found/not-found.component';
import { TestesComponent } from './testes/testes.component';
import { ErroRequestComponent } from './erro-request/erro-request.component';
import { RecuperarSenhaComponent } from './cliente/recuperacao-senha/recuperar-senha/recuperar-senha.component';
import { NovaSenhaComponent } from './cliente/recuperacao-senha/nova-senha/nova-senha.component';
import { ClienteEditarComponent } from './cliente/cadastro/cliente-editar/cliente-editar.component';

import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, LinkedInLoginProvider, SocialLoginModule } from 'angularx-social-login';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {}

// social login
const config = new AuthServiceConfig([
  /*{
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('624796833023-clhjgupm0pu6vgga7k5i5bsfp6qp6egh.apps.googleusercontent.com')
  },*/
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('665520850103-9f3v78tj7jh7qacj8e5ic2ifvnipp7so.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('398998457475386')
  },
]);

export function provideConfig() {
  return config;
}
// end social login

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: false
};

@NgModule({
  declarations: [
    AppComponent,
    ClienteCadastroComponent,
    CardapioComponent,
    SacolaComponent,
    LoginComponent,
    PedidoConcluidoComponent,
    HeaderComponent,
    CalcularTaxaEntregaComponent,
    ContatoComponent,
    AvaliacaoComponent,
    PedidosComponent,
    ConsultaEnderecosComponent,
    NotFoundComponent,
    TestesComponent,
    ErroRequestComponent,
    RecuperarSenhaComponent,
    NovaSenhaComponent,
    ClienteEditarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig), // mascara currency
    NgxSpinnerModule, // spinner loading
    NgxMaskModule.forRoot(options),// mascara
    BrowserAnimationsModule, // para uso do toast
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    SocialLoginModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
