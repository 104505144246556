import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, tap } from 'rxjs/operators';

import { CategoriaProduto } from '../models/categoriaProduto';
import { CrudService } from './base/crud.service';
import { URI } from '../app.api';

@Injectable({
  providedIn: 'root'
})
export class CategoriaProdutoService extends CrudService<CategoriaProduto>{

  constructor(protected http: HttpClient) {
    super(http, 'categoriaProduto', URI);
  }

  ListByEmpresa(empresaId: number) {
    return this.http.get<CategoriaProduto[]>(`${URI}/${this.endpoint}/listbyempresanaopausados/${empresaId}`)
      .pipe(
        tap()
      );
  }
}
