import { HttpClient } from '@angular/common/http';
import { delay, tap, take } from 'rxjs/operators';
import { URI } from 'src/app/app.api';

export class CrudService<T> {
  constructor(
      protected http: HttpClient,
      protected endpoint: string, 
      private API_URL = URI) { }

  List() {
      return this.http.get<T[]>(`${this.API_URL}/${this.endpoint}/get`)
          .pipe(
              delay(2000),
              tap()
          );
  }

  Get(id: number) {
      return this.http.get<T>(`${this.API_URL}/${this.endpoint}/get/${id}`).pipe(take(1));
  }

   Save(record: T) {
      return this.http.post(`${this.API_URL}/${this.endpoint}/post`, record).pipe(take(1));
  }

  Delete(id: number) {
      return this.http.delete(`${this.API_URL}/${this.endpoint}/delete/${id}`).pipe(take(1));
  }
}
