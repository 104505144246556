import { Complemento } from './complemento';
import { CategoriaComplemento } from './categoriaComplemento';

export class ItemSacolaComplemento {

    public complemento: Complemento;
    public categoriaComplemento: CategoriaComplemento;
    public categoriaComplementoId: number;
    public quantidade: number = 1;

   
    Total(): number {
        return this.complemento.valorVenda * this.quantidade;
    }
}