import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, take, timeInterval } from 'rxjs/operators';

import { Cliente } from '../models/cliente';
import { URI } from '../app.api';
import { ClienteEndereco } from '../models/clienteEndereco';
import { Empresa } from '../models/empresa';
import * as CryptoJS from 'crypto-js';
import { EmpresaService } from './empresa.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  ge(ge: any) {
    throw new Error("Method not implemented.");
  }

  private currentUserSubject: BehaviorSubject<Cliente>;
  private currentEnderecoEntregaSubject: BehaviorSubject<ClienteEndereco>;
  private currentEmpresaSubject: BehaviorSubject<Empresa>;

  public currentUser: Observable<Cliente>;
  public currentEnderecoEntrega: Observable<ClienteEndereco>;
  public currentEmpresa: Observable<Empresa>;


  constructor(private http: HttpClient,
    private router: Router,
    private empresaService: EmpresaService) {

    this.currentUserSubject = new BehaviorSubject<Cliente>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();


    this.currentEnderecoEntregaSubject = new BehaviorSubject<ClienteEndereco>(JSON.parse(localStorage.getItem('enderecoEntrega')));
    this.currentEnderecoEntrega = this.currentEnderecoEntregaSubject.asObservable();

    this.currentEmpresaSubject = new BehaviorSubject<Empresa>(null);
    this.currentEmpresa = this.currentEmpresaSubject.asObservable();

  }

  startTimer() {
    setInterval(() => {
      if (this.currentEmpresaValue) {
        this.empresaService.Get(this.currentEmpresaValue.id).subscribe(empresa => {
          this.currentEmpresaSubject.next(empresa);
        });
      }
    }, 5000)
  }

  public get currentUserValue(): Cliente {
    return this.currentUserSubject.value;
  }

  public get currentEnderecoEntregaValue(): ClienteEndereco {
    return this.currentEnderecoEntregaSubject.value;
  }

  public get currentEmpresaValue(): Empresa {
    return this.currentEmpresaSubject.value;
  }

  login(cliente: Cliente) {
    return this.http.post<any>(`${URI}/logincliente/login`, cliente)
      .pipe(map(cliente => {

        //apagar isso somente para teste
        cliente.accessToken = '1234'

        if (cliente && cliente.accessToken) {
          this.setCliente(cliente);
        }

        return cliente;
      }));
  }


  recuperarSenha(cliente: Cliente) {
    return this.http.post(`${URI}/logincliente/recuperarsenha`, cliente)
      .pipe(take(1));
  }

  isLogged() {

    // obter usuario logado
    let userLogado: Cliente;
    this.currentUser.subscribe(user => {
      userLogado = user;
    });
    return userLogado;
  }

  setEnderecoEntrega(enderecoEntrega: ClienteEndereco) {
    localStorage.setItem('enderecoEntrega', JSON.stringify(enderecoEntrega));
    this.currentEnderecoEntregaSubject.next(enderecoEntrega);
  }

  setEmpresaId(empresaId: number) {
    sessionStorage.setItem('emp', empresaId.toString());
    this.empresaService.Get(empresaId).subscribe(empresa => {
      this.currentEmpresaSubject.next(empresa);
    });
  }

  setCliente(cliente: Cliente) {
    localStorage.setItem('currentUser', JSON.stringify(cliente));
    this.currentUserSubject.next(cliente);
  }

  removeEnderecoEntrega() {
    localStorage.removeItem('enderecoEntrega');

  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

}
