import { Component, OnInit } from '@angular/core';

import { PedidoService } from '../services/pedido.service';
import { Pedido } from '../models/pedido';
import { AuthenticationService } from '../services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any


@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {

  pedidos: Pedido[]
  pedidoSelecionado: Pedido;

  // usada para mostrar div quando nao localiza pedidos
  private buscouPedidos = false;

  constructor(private pedidoService: PedidoService,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService) {

    // inicializar modal
    $(document).ready(function () {
      $('.modal').modal();
    });

  }

  ngOnInit() {

    this.spinner.show();

    let clienteId = this.authService.currentUserValue.id;

    if (clienteId)
      this.startTimer(clienteId);

    this.pedidoService.ListPorCliente(clienteId).subscribe(pedidos => {
      //order by id
      this.pedidos = pedidos.sort((a, b) => (b.id) - (a.id));
      this.spinner.hide();
      this.buscouPedidos = true;
    },
      error => {
        console.log(error)
        this.spinner.hide();
      });

  }

  startTimer(clienteId: number) {
    setInterval(() => {
      this.pedidoService.ListPorCliente(clienteId).subscribe(pedidos => {
        //order by id
        this.pedidos = pedidos.sort((a, b) => (b.id) - (a.id));
      });
    }, 5000)
  }

  SelecionarPedido(pedido: Pedido) {

    this.pedidoService.GetPedidoCompleto(pedido.id).subscribe(pedido => {
      this.pedidoSelecionado = pedido
    });
  }

  FecharModal() {
    this.pedidoSelecionado = null;
  }

}
