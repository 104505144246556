import { Component, OnInit, Input, ɵConsole, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { Cliente } from 'src/app/models/cliente';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SacolaService } from 'src/app/services/shared/sacola.service';

import { AuthService, SocialUser, LoginOpt } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider, LinkedInLoginProvider } from 'angularx-social-login';
import { Empresa } from 'src/app/models/empresa';

declare var $: any

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  mensagemRetornoErro = null;
  empresa: Empresa;
  emailClicado: boolean;
  realizandoSocialLogin: boolean;

  // referencia do componente que esta chamando
  @Input('componenteChamando') componenteChamando;

  @Output() modalAberto = new EventEmitter();
  @Input() abrirModal: boolean

  submitted = false;

  usersSocialLogin: SocialUser;


  constructor(private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private sacolaService: SacolaService,
    private router: Router,
    private authServiceSocialLogin: AuthService) {

    // inicializar modal
    $(document).ready(function () {
      $('.modal').modal({
        dismissible: false
      });
    });
  }


  ngOnInit() {

    this.empresa = this.authService.currentEmpresaValue;

    this.authServiceSocialLogin.authState.subscribe((user) => {
    });

    $(document).ready(function () {
      $('#modalLogin').modal('open');
    });

    this.loginForm = new FormGroup({
      email: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.email]),
      senha: new FormControl({ value: null, disabled: false }),

    })

  }

  Logar(cliente: Cliente) {

    this.submitted = true;

    // passa id da empresa que o cliente esta visualizando o cardapio
    cliente.empresaId = this.authService.currentEmpresaValue.id;

    this.authService.login(cliente).subscribe(
      clienteRetornado => {

        //navega para cadastro caso for um novo cliente
        if (clienteRetornado.id == 0) {

          // passa o parametro da pagina que esta chamando o cadastro
          this.router.navigate(['/cliente-cadastro'],
            { queryParams: { page: 'login' } });


          $('#modalLogin').modal('close');
          this.modalAberto.emit(false);
          return;
        }


        $('#modalLogin').modal('close');
        this.modalAberto.emit(false);

        if (this.sacolaService.itensSacola.length > 0) {
          this.router.navigate(['/sacola']);

          // verificar se componente sacola esta chamando a tela de login para recarregar e preencher dados do cliente logado
          if (this.componenteChamando != undefined)
            this.componenteChamando.ngOnInit()
          return;
        }

        /*// voltar para a pagina que solicitou o login
        if (this.componenteChamando)
          this.componenteChamando.ngOnInit()*/
      },
      error => {
        this.mensagemRetornoErro = error.error.Message;
        this.submitted = false;
      });

  }

  navegarParaCadastro() {
    // passa o parametro da pagina que esta chamando o cadastro
    this.router.navigate(['/cliente-cadastro'],
      { queryParams: { page: 'login' } });

    $('#modalLogin').modal('close');
    this.modalAberto.emit(false);
    return;
  }

  FecharModal() {
    this.modalAberto.emit(false);
  }

  signInWithGoogle(): void {

    this.realizandoSocialLogin = true;

    let cliente = new Cliente;

    this.authServiceSocialLogin.signIn(GoogleLoginProvider.PROVIDER_ID)
      .then(
        res => { // Success
          cliente.email = res.email;
          cliente.nome = res.name;
          cliente.isSocialLogin = true;
          this.Logar(cliente);

        })
      .catch(error => {
        this.realizandoSocialLogin = false;
      });

  }

  signInWithFB(): void {

    this.realizandoSocialLogin = true;

    let cliente = new Cliente;

    this.authServiceSocialLogin.signIn(FacebookLoginProvider.PROVIDER_ID)
      .then(
        res => { // Success
          cliente.email = res.email;
          cliente.nome = res.name;
          cliente.isSocialLogin = true;
          this.Logar(cliente);

        })
      .catch(error => {
        this.realizandoSocialLogin = false;
      });

  }

  LoginByEmail() {
    this.emailClicado = true;
  }



}
