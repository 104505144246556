import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-erro-request',
  templateUrl: './erro-request.component.html',
  styleUrls: ['./erro-request.component.css']
})
export class ErroRequestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
