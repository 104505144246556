import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, tap } from 'rxjs/operators';

import { Pedido } from '../models/pedido';
import { CrudService } from './base/crud.service';
import { URI } from '../app.api';

@Injectable({
  providedIn: 'root'
})
export class PedidoService extends CrudService<Pedido>{

  constructor(protected http: HttpClient) {
    super(http, 'pedido', URI);
  }

  GetPedidoCompleto(id: number) {
    return this.http.get<Pedido>(`${URI}/${this.endpoint}/getpedidocompleto/${id}`)
      .pipe(
        delay(2000),
        tap()
      );
  }

  ListPorCliente(clienteId: number) {
    return this.http.get<Pedido[]>(`${URI}/${this.endpoint}/listbycliente/${clienteId}`)
      .pipe(
        delay(2000),
        tap()
      );
  }

  ConsultarBinCartao(numeroCartao: string, empresaId: number) {
    return this.http.get<any>(`${URI}/${this.endpoint}/consultarbincartao/${numeroCartao}/${empresaId}`)
      .pipe(
        tap()
      );
  }
}
