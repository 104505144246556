import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgxSpinnerService } from 'ngx-spinner';
import { delay, finalize, catchError, retry } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(private authService: AuthenticationService,
        private spinner: NgxSpinnerService,
        private router: Router, ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //this.spinner.show();

        if (this.authService.isLogged()) {

            const authRequest = request.clone(
                { setHeaders: { 'Authorization': `Bearer ${this.authService.isLogged()}` } })
            return next.handle(authRequest)
                .pipe(
                    retry(1),
                    catchError((error: HttpErrorResponse) => {
                        // token experiar retornar para pagina de login
                        if (error.status == 401) {
                            this.router.navigate(['']);
                            this.authService.logout()
                        }

                        return throwError(error);
                    }),
                    //finalize(() => this.spinner.hide())
                )

        } else {
            return next.handle(request)
                .pipe(
                    delay(5000),
                  //  finalize(() => this.spinner.hide())
                )
        }

    }
}