import { CardBrandEnum } from 'src/app/models/enums/cardBrand';

export class PagamentoOnlineUtil {

    static retornarBandeiraCartao(nomeBandeira: string): CardBrandEnum {
        switch (nomeBandeira) {
            case "AMEX": {
                return CardBrandEnum.Amex;
            }

            case "AURA": {
                return CardBrandEnum.Aura;
            }

            case "DINERS": {
                return CardBrandEnum.Diners;
            }

            case "DISCOVER": {
                return CardBrandEnum.Discover;
            }

            case "ELO": {
                return CardBrandEnum.Elo;
            }

            case "JCB": {
                return CardBrandEnum.JCB;
            }

            case "MASTERCARD": {
                return CardBrandEnum.Master;
            }

            case "VISA": {
                return CardBrandEnum.Visa;
            }
        }
    }

    static formatarDataValidade(dataValidadeSemFormatar: string): Date {
        let mesExtraido = Number(dataValidadeSemFormatar.substr(0, 2));
        let anoExtraido = Number(dataValidadeSemFormatar.substr(2, 8));

        // remove 1 mes pois o mes inicia em 0
        let dataFormatada = new Date().setFullYear(anoExtraido, mesExtraido - 1);

        return new Date(dataFormatada);

    }

}