import { Component, OnInit } from '@angular/core';

declare var $: any

@Component({
  selector: 'app-pedido-concluido',
  templateUrl: './pedido-concluido.component.html',
  styleUrls: ['./pedido-concluido.component.css']
})
export class PedidoConcluidoComponent implements OnInit {

  constructor() {

    // bloquear botao voltar browser e quando clicar em voltar nao ir para o carrinho novamente
    history.pushState(null, null, 'no-back-button');
    window.addEventListener('popstate', function (event) {
      history.pushState(null, null, 'no-back-button');
    });

  }

  ngOnInit() {
  }

}
