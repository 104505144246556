import { ItemPedido } from './itemPedido';
import { Complemento } from './complemento';

export class ItemPedidoComplemento {

    public id: number;
    public itemPedido: ItemPedido;
    public itemPedidoId: number;
    public complemento: Complemento;
    public complementoId: number;
    public quantidade: number;
    public valorUnitario: number;
    public valorTotal: number;
}