import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ClienteService } from 'src/app/services/cliente.service';
import { Cliente } from 'src/app/models/cliente';

@Component({
  selector: 'app-cliente-editar',
  templateUrl: './cliente-editar.component.html',
  styleUrls: ['./cliente-editar.component.css']
})
export class ClienteEditarComponent implements OnInit {

  cliente: Cliente;
  clienteForm: FormGroup;
  editandoCadastro: boolean

  constructor(private clienteService: ClienteService,
    private router: Router,
    private authService: AuthenticationService,
    private toastr: ToastrService) { }

  ngOnInit() {

    this.cliente = this.authService.currentUserValue;

    this.clienteForm = new FormGroup({
      nome: new FormControl({ value: null, disabled: false }, Validators.required),
      email: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.email]),
      telefone: new FormControl({ value: null, disabled: false }, Validators.required),
      cpf: new FormControl({ value: null, disabled: false }),
    })

    this.clienteForm.disable();

    this.PopularCampos(this.cliente);


  }

  get fCliente() { return this.clienteForm.controls; }

  PopularCampos(cliente: Cliente) {
    this.fCliente.nome.setValue(cliente.nome);
    this.fCliente.email.setValue(cliente.email);
    this.fCliente.telefone.setValue(cliente.telefone);
    this.fCliente.cpf.setValue(cliente.cpf);

  }

  EditarCadastroClick() {
    this.editandoCadastro = true;
    this.clienteForm.enable();
  }

  SalvarCadastro(cliente: Cliente) {

    cliente.id = this.cliente.id;
    cliente.enderecos = this.cliente.enderecos;

    this.clienteService.EditarDadosCadastrais(cliente).subscribe(data => {

      this.authService.setCliente(cliente);
      this.editandoCadastro = false;
      this.clienteForm.disable();
      this.toastr.success('Dados alterados com sucesso')

    }, error => {
      this.toastr.error(error.error.Message, 'Atenção');
      console.log(error.error.Message);
    });

  }

}
