import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CrudService } from './base/crud.service';
import { URI } from '../app.api';
import { ClienteEndereco } from '../models/clienteEndereco';
import { delay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClienteEnderecoService extends CrudService<ClienteEndereco> {

  constructor(protected http: HttpClient) {
    super(http, 'clienteEndereco', URI);
  }

  ListPorCliente(clienteId: number) {
    return this.http.get<ClienteEndereco[]>(`${URI}/${this.endpoint}/listbycliente/${clienteId}`)
      .pipe(
        delay(2000),
        tap()
      );
  }
}
