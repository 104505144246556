import { Injectable } from '@angular/core';

import { CrudService } from './base/crud.service';
import { URI } from '../app.api';
import { HttpClient } from '@angular/common/http';
import { ClienteEndereco } from '../models/clienteEndereco';
import { take } from 'rxjs/internal/operators/take';

@Injectable({
  providedIn: 'root'
})
export class BuscaEnderecoService extends CrudService<ClienteEndereco> {

  constructor(protected http: HttpClient) {
    super(http, 'homeAdmin', URI);
  }

  BuscarEnderecoPeloCep(clienteEndereco: ClienteEndereco) {
    return this.http.post(`${URI}/buscarenderecopelocep`, clienteEndereco).pipe(take(1));
  }

  CalcularTaxa(clienteEndereco: ClienteEndereco) {
    return this.http.post(`${URI}/calculartaxaentrega`, clienteEndereco).pipe(take(1));
  }
}
