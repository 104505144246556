import { Produto } from './produto';
import { ItemSacolaComplemento } from './itemSacolaComplemento';

export class ItemSacola {

    public produto: Produto;
    public quantidade: number = 1;
    public observacao: string;
    public itemSacolaComplementos: ItemSacolaComplemento[];
    public valorComplementos: number = 10;



    Total(): number {
        return this.produto.valorVenda * this.quantidade
        + this.quantidade * this.itemSacolaComplementos.filter(item => item.complemento)
        .reduce((sum, current) => sum + current.Total(), 0);
    }

}