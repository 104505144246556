import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Cliente } from 'src/app/models/cliente';
import { Empresa } from 'src/app/models/empresa';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.css']
})
export class RecuperarSenhaComponent implements OnInit {

  clienteForm: FormGroup;
  empresa: Empresa;
  mensagemRetorno = null;
  mostrarForm = true;

  submitted = false;

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {

    this.empresa = this.authService.currentEmpresaValue;

    this.clienteForm = new FormGroup({
      email: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.email]),
    })

  }

  EnviarEmail(cliente: Cliente) {

    this.submitted = true;

    cliente.empresaId = this.empresa.id;

    this.authService.recuperarSenha(cliente).subscribe(data => {
      this.mensagemRetorno = 'Sucesso \n verifique sua caixa de entrada com o link'
      this.mostrarForm = false;

    },
      error => {
        this.submitted = false;
        this.mensagemRetorno = error.error.Message;
      });
  }

}
