import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay, tap } from 'rxjs/operators';

import { EmpresaFormaPagamento } from '../models/empresaFormaPagamento';
import { CrudService } from './base/crud.service';
import { URI } from '../app.api';

@Injectable({
  providedIn: 'root'
})
export class EmpresaFormaPagamentoService extends CrudService<EmpresaFormaPagamento>{

  constructor(protected http: HttpClient) {
    super(http, 'empresaformapagamento', URI);
  }

  ListPorEmpresa(empresaId:number) {
    return this.http.get<EmpresaFormaPagamento[]>(`${URI}/${this.endpoint}/Listformasbyempresa/${empresaId}`)
      .pipe(
        delay(2000),
        tap()
      );
  }
}
