import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClienteEndereco } from 'src/app/models/clienteEndereco';
import { BuscaEnderecoService } from 'src/app/services/busca-endereco.service';
import { ClienteEnderecoService } from 'src/app/services/cliente-endereco.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any

@Component({
  selector: 'app-consulta-enderecos',
  templateUrl: './consulta-enderecos.component.html',
  styleUrls: ['./consulta-enderecos.component.css']
})
export class ConsultaEnderecosComponent implements OnInit {

  @Output() modalAberto = new EventEmitter();
  @Input() abrirModal: boolean
  @Output() enderecoSelecionado = new EventEmitter<ClienteEndereco>();

  enderecosCliente: ClienteEndereco[];

  endereco: ClienteEndereco;
  erro: string;
  spinnerText: string;
  submitted = false;

  submittedCalculoTaxa = false;


  cepFoiVerificado = false;
  calculouTaxa = false;



  enderecoForm: FormGroup;

  constructor(private authService: AuthenticationService,
    private buscaEnderecoService: BuscaEnderecoService,
    private clienteEnderecoService: ClienteEnderecoService,
    private spinner: NgxSpinnerService) {

    // inicializar modal
    $(document).ready(function () {
      $('.modal').modal({
        dismissible: false
      });
    });

  }

  ngOnInit() {

    this.ObterEnderecosCadastrados()

    $(document).ready(function () {
      $('#modalConsultarEnderecos').modal('open');
    });

    this.enderecoForm = new FormGroup({
      cep: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.minLength(8)]),
      logradouro: new FormControl({ value: null, disabled: false }, Validators.required),
      numero: new FormControl({ value: null, disabled: false }, Validators.required),
      bairro: new FormControl({ value: null, disabled: false }, Validators.required),
      complemento: new FormControl({ value: null, disabled: false }),
      pontoReferencia: new FormControl({ value: null, disabled: false }),
    });
  }

  get fEndereco() { return this.enderecoForm.controls; }

  ObterEnderecosCadastrados() {

    this.spinner.show();

    let clienteId = this.authService.currentUserValue.id;

    this.clienteEnderecoService.ListPorCliente(clienteId).subscribe(enderecos => {
      this.enderecosCliente = enderecos;

      this.spinner.hide();

    });
  }

  CadastrarNovoEndereco() {
    $('#modalCadastrarEndereco').modal('open');
    //this.mostrarModalCadastroEndereco = true;
  }

  FecharModal() {
    $('#modalConsultarEnderecos').modal('close');
    $('#modalCadastrarEndereco').modal('close');
    this.modalAberto.emit(false);
  }

  SelecionarEndereco(endereco: ClienteEndereco) {
    $('#modalConsultarEnderecos').modal('close');
    this.enderecoSelecionado.emit(endereco);
    this.modalAberto.emit(false);
  }

  BuscarEnderecoPeloCep(clienteEndereco: ClienteEndereco) {

    this.erro = null;
    this.calculouTaxa = false;

    if (clienteEndereco.cep.length >= 8) {

      clienteEndereco.empresaId = this.authService.currentEmpresaValue.id;
      clienteEndereco.dataAlteracao = new Date();


      this.spinner.show();


      this.buscaEnderecoService.BuscarEnderecoPeloCep(clienteEndereco).subscribe((endereco: ClienteEndereco) => {

        this.cepFoiVerificado = true;

        this.endereco = endereco;


        this.fEndereco.logradouro.setValue(endereco.logradouro);
        this.fEndereco.bairro.setValue(endereco.bairro);
        this.fEndereco.numero.setValue(null);
        this.fEndereco.complemento.setValue(null);


        this.spinner.hide();

      },
        error => {
          this.spinner.hide();
          this.erro = error.error.Message;
          console.log(error)
          this.cepFoiVerificado = false;
        });

    }
  }

  CalcularTaxa(clienteEndereco: ClienteEndereco) {


    this.submittedCalculoTaxa = true;
    this.calculouTaxa;

    clienteEndereco.empresaId = this.authService.currentEmpresaValue.id;
    clienteEndereco.cidade = this.endereco.cidade;
    clienteEndereco.uf = this.endereco.uf;

    this.buscaEnderecoService.CalcularTaxa(clienteEndereco).subscribe((valorTaxa: number) => {
      this.calculouTaxa = true;
      this.endereco.taxa = valorTaxa;

      this.submittedCalculoTaxa = false;

    },
      error => {
        this.spinner.hide();
        this.erro = error.error.Message;
        this.calculouTaxa = false;
        this.submittedCalculoTaxa = false;
      });

  }

  OnChangeInputLogradouro() {
    this.calculouTaxa = false;
    this.erro = null;

  }

  OnChangeInputBairro() {
    this.calculouTaxa = false;
    this.erro = null;

  }

  OnChangeInputNumero() {
    this.calculouTaxa = false;
    this.erro = null;

  }

  SalvarEndereco(clienteEndereco: ClienteEndereco) {

    this.submitted = true;

    clienteEndereco.clienteId = this.authService.currentUserValue.id;
    clienteEndereco.dataAlteracao = new Date();
    clienteEndereco.cep = clienteEndereco.cep.replace('-', '');
    clienteEndereco.taxa = this.endereco.taxa;

    this.clienteEnderecoService.Save(clienteEndereco).subscribe(() => {

      $('#modalCadastrarEndereco').modal('close');
      this.ObterEnderecosCadastrados();

    }, error => {
      this.submitted = false;
      console.log(error.error.Message);
    });
  }

}
