import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URI } from '../app.api';
import { take } from 'rxjs/operators';

import { CrudService } from './base/crud.service';
import { Cliente } from '../models/cliente';

@Injectable({
  providedIn: 'root'
})
export class ClienteService extends CrudService<Cliente> {

  constructor(protected http: HttpClient) {
    super(http, 'cliente', URI);
  }

  EditarDadosCadastrais(cliente: Cliente) {
    return this.http.post(`${URI}/${this.endpoint}/editardadoscadastrais`, cliente).pipe(take(1));
}

}
