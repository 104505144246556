import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { BuscaEnderecoService } from 'src/app/services/busca-endereco.service';
import { ClienteEndereco } from 'src/app/models/clienteEndereco';
import { Cliente } from 'src/app/models/cliente';
import { ClienteService } from 'src/app/services/cliente.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { formControlBinding } from '@angular/forms/src/directives/reactive_directives/form_control_directive';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidaCpfCnpj } from './utils/validaCpfCnpj';

@Component({
  selector: 'app-cliente-cadastro',
  templateUrl: './cliente-cadastro.component.html',
  styleUrls: ['./cliente-cadastro.component.css']
})
export class ClienteCadastroComponent implements OnInit {

  enderecoForm: FormGroup;
  clienteForm: FormGroup;
  endereco: ClienteEndereco;

  submitted = false;
  submittedCalculoTaxa = false;

  cepFoiVerificado = false;
  calculouTaxa = false;
  retornoErroConsultaCep: string;
  spinnerText: string;
  pageChamando: string

  clienteCadastrandoPorSocialLogin: boolean;

  enderecosCadastrados = new Array<ClienteEndereco>();

  constructor(
    private buscaEnderecoService: BuscaEnderecoService,
    private clienteService: ClienteService,
    private router: Router,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute) { }

  ngOnInit() {

    this.pageChamando = this.route.snapshot.queryParams.page

    this.enderecoForm = new FormGroup({
      cep: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.minLength(8)]),
      logradouro: new FormControl({ value: null, disabled: false }, Validators.required),
      numero: new FormControl({ value: null, disabled: false }, Validators.required),
      bairro: new FormControl({ value: null, disabled: false }, Validators.required),
      complemento: new FormControl({ value: null, disabled: false }),
      pontoReferencia: new FormControl({ value: null, disabled: false }),

    })


    this.clienteForm = new FormGroup({
      nome: new FormControl({ value: null, disabled: false }, Validators.required),
      email: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.email]),
      telefone: new FormControl({ value: null, disabled: false }, Validators.required),
      cpf: new FormControl({ value: null, disabled: false }),
      senha: new FormControl({ value: null, disabled: false }, Validators.required),
      confirmacaoSenha: new FormControl({ value: null, disabled: false }, Validators.required),
    })

    this.ObterClienteCadastrando();
    this.ObterEnderecoConsultado();

  }

  // obtem cliente se cadastrando via social login
  ObterClienteCadastrando() {

    let clienteCadastrando = this.authService.currentUserValue

    if (clienteCadastrando) {
      this.fCliente.email.setValue(clienteCadastrando.email);
      this.fCliente.nome.setValue(clienteCadastrando.nome);
      this.clienteCadastrandoPorSocialLogin = true;
      this.fCliente.senha.setValue('socialLogin#@23')
      this.fCliente.confirmacaoSenha.setValue('socialLogin#@23')

    }
  }

  // convenience getter for easy access to form fields
  get fCliente() { return this.clienteForm.controls; }
  get fEndereco() { return this.enderecoForm.controls; }

  // metodo resposavel por obter o endereco que o cliente ja verificou a disponibilidade de entrega
  ObterEnderecoConsultado() {

    let enderecoJaInformado = this.authService.currentEnderecoEntregaValue;
    this.cepFoiVerificado = true;
    this.calculouTaxa = true;

    if (enderecoJaInformado) {
      this.endereco = enderecoJaInformado;
      this.fEndereco.numero.setValue(enderecoJaInformado.numero);
      this.fEndereco.cep.setValue(enderecoJaInformado.cep);
      this.fEndereco.logradouro.setValue(enderecoJaInformado.logradouro);
      this.fEndereco.bairro.setValue(enderecoJaInformado.bairro);
      this.fEndereco.complemento.setValue(enderecoJaInformado.complemento);
      this.fEndereco.pontoReferencia.setValue(enderecoJaInformado.pontoReferencia);

    }

  }


  BuscarEnderecoPeloCep(clienteEndereco: ClienteEndereco) {

    this.calculouTaxa = false;

    clienteEndereco.empresaId = this.authService.currentEmpresaValue.id;


    this.retornoErroConsultaCep = null;
    this.endereco = null;

    if (clienteEndereco.cep.length >= 8) {

      this.spinner.show();

      this.buscaEnderecoService.BuscarEnderecoPeloCep(clienteEndereco).subscribe((endereco: ClienteEndereco) => {

        this.enderecoForm.get('logradouro').setValue(endereco.logradouro);
        this.enderecoForm.get('bairro').setValue(endereco.bairro);
        this.cepFoiVerificado = true;
        this.endereco = endereco;

        this.spinner.hide();

      },
        error => {
          this.spinner.hide();
          this.retornoErroConsultaCep = error.error.Message;
        });
    }
  }

  CalcularTaxa(clienteEndereco: ClienteEndereco) {


    this.submittedCalculoTaxa = true;

    clienteEndereco.empresaId = this.authService.currentEmpresaValue.id;
    clienteEndereco.cidade = this.endereco.cidade;
    clienteEndereco.uf = this.endereco.uf;

    this.buscaEnderecoService.CalcularTaxa(clienteEndereco).subscribe((valorTaxa: number) => {
      this.calculouTaxa = true;
      this.endereco.taxa = valorTaxa;

      this.submittedCalculoTaxa = false;

    },
      error => {
        this.spinner.hide();
        this.retornoErroConsultaCep = error.error.Message;
        this.calculouTaxa = false;
        this.submittedCalculoTaxa = false;
      });

  }

  SalvarEndereco(endereco: ClienteEndereco) {

    endereco.taxa = this.endereco.taxa;
    endereco.dataAlteracao = new Date();
    endereco.cidade = this.endereco.cidade;
    endereco.uf = this.endereco.uf;

    this.enderecosCadastrados.push(endereco);
  }

  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.clienteForm.invalid) {
      return;
    }

  }

  OnChangeInputLogradouro() {
    this.calculouTaxa = false;
    this.retornoErroConsultaCep = null;
  }

  OnChangeInputBairro() {
    this.calculouTaxa = false;
    this.retornoErroConsultaCep = null;

  }

  OnChangeInputNumero() {
    this.calculouTaxa = false;
    this.retornoErroConsultaCep = null;

  }

  ValidarCpf() {
    let cpf = this.fCliente.cpf.value;

    if (cpf != null) {
      if (!ValidaCpfCnpj.validaCpfCnpj(cpf)) {
        this.toastr.error('CPF inválido')
        this.fCliente.cpf.setValue(null);
      }
    }

  }


  SalvarCadastro(cliente: Cliente) {

    this.submitted = true;

    this.SalvarEndereco(this.enderecoForm.value);

    let empresaId = this.authService.currentEmpresaValue.id;

    cliente.empresaId = empresaId;
    cliente.dataCadastro = new Date();

    cliente.enderecos = this.enderecosCadastrados;

    this.clienteService.Save(cliente).subscribe(data => {

      if (this.pageChamando == 'login') {
        this.router.navigate(['/cardapio'])
        cliente.isSocialLogin = true;
        this.authService.login(cliente).subscribe(() => { });
        return;
      }

    }, error => {
      this.toastr.error(error.error.Message, 'Atenção');
      this.submitted = false;
    });

  }

}
