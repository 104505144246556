import { Cliente } from './cliente';
import { ClienteEndereco } from './clienteEndereco';
import { ItemPedido } from './itemPedido';
import { FormaPagamento } from './formaPagamento';
import { CardBrandEnum } from './enums/cardBrand';

export class Pedido {

    public id: number;
    public empresaId: number;
    public data: Date;
    public hora: number;
    public cliente: Cliente;
    public clienteId: number;
    public enderecoEntrega: ClienteEndereco;
    public enderecoEntregaId: number;
    public status: number;
    public cpfNaNota: boolean;
    public valorTaxaEntrega: number;
    public subTotal: number;
    public valorTotal: number;
    public valorTotalComplementos: number;
    public itens: ItemPedido[];
    public formaPagamento: FormaPagamento;
    public formaPagamentoId: number;
    public valorTroco: number;
    public motivoCancelamento: string;

    // transfer model
    public CartaoCredito: CartaoCredito;
}

// classes transfer model usado apenas para enviar informacoes para api
export class CartaoCredito {

    numero: string;
    validade: Date;
    codigoSeguranca: string;
    bandeira: CardBrandEnum;
    iconeHtml: string;
    nomeBandeira: string;
}