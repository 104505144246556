import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CategoriaProduto } from 'src/app/models/categoriaProduto';
import { CategoriaProdutoService } from 'src/app/services/categoria-produto.service';
import { Produto } from 'src/app/models/produto';
import { SacolaService } from 'src/app/services/shared/sacola.service';
import { ItemSacola } from 'src/app/models/itemSacola';
import { tryParse } from 'selenium-webdriver/http';
import { Complemento } from 'src/app/models/complemento';
import { ItemSacolaComplemento } from 'src/app/models/itemSacolaComplemento';
import { registerContentQuery } from '@angular/core/src/render3';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { CategoriaComplemento } from 'src/app/models/categoriaComplemento';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any

@Component({
  selector: 'app-cardapio',
  templateUrl: './cardapio.component.html',
  styleUrls: ['./cardapio.component.css']
})
export class CardapioComponent implements OnInit {

  mostrarCalculoTaxa: boolean;
  botaoAdicionarItemDesabilitado: boolean = true;

  categoriasProduto: CategoriaProduto[] = new Array;
  produtoSelecionado: Produto;
  observacaoItem: string;
  quantidadeItem: number = 1;

  constructor(private categoriaProdutoService: CategoriaProdutoService,
    private sacolaService: SacolaService,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService) {

    // inicializar jquery
    $(document).ready(function () {
      $('.modal').modal({
        dismissible: false
      });
      $('.collapsible').collapsible();
    });

    // ampliar imagem
    $(document).ready(function () {
      $('.materialboxed').materialbox();
    });

  }

  ngOnInit() {
    this.BuscarCategorias(Number(sessionStorage.getItem('emp')));
  }

  BuscarCategorias(empresaId: number) {

    this.spinner.show();

    this.categoriaProdutoService.ListByEmpresa(empresaId).subscribe((categorias: CategoriaProduto[]) => {
      this.categoriasProduto = categorias

      this.spinner.hide();

    },
      error => {
        this.spinner.hide();
        this.router.navigate(['/erro']);
      });

  }

  SelecionarProduto(produto: Produto) {

    if (this.authService.currentEmpresaValue.abertaFechada == 'FECHADA') {
      this.toastr.error('Restaurante indisponivel')
      $(document).ready(function () {
        $('#modalItemSelecionado').modal('close');
      });
      return
    }

    if (!this.authService.currentEnderecoEntregaValue && !this.authService.currentUserValue) {

      //fecha modal produto selecionado antes de abrir o de calcular taxa
      $(document).ready(function () {
        $('#modalItemSelecionado').modal('close');
      });

      this.mostrarCalculoTaxa = true;
    }

    this.produtoSelecionado = produto;

  }

  AddQuantidadeItem() {
    this.quantidadeItem++;
  }

  RemoveQuantidadeItem() {

    if (this.quantidadeItem > 1)
      this.quantidadeItem--;
  }

  AddItem() {


    let itemSacola = new ItemSacola();

    itemSacola.produto = this.produtoSelecionado;
    itemSacola.quantidade = this.quantidadeItem;
    itemSacola.observacao = this.observacaoItem;
    itemSacola.itemSacolaComplementos = this.AddComplementos();

    this.showSuccess(itemSacola);

    this.sacolaService.AddItem(itemSacola)


    this.LimparCampos();

  }

  AddComplementos(): ItemSacolaComplemento[] {

    if (!this.produtoSelecionado)
      return;

    let complementosInserido = new Array<ItemSacolaComplemento>();

    this.produtoSelecionado.categoriasComplemento.forEach(catComp => {

      catComp.complementos.forEach(comp => {


        if (comp.quantidadeInserida > 0) {

          let itemSacolaComplemento = new ItemSacolaComplemento();

          itemSacolaComplemento.complemento = comp;
          itemSacolaComplemento.quantidade = comp.quantidadeInserida;

          complementosInserido.push(itemSacolaComplemento);

        }

      });

    });

    this.DesabilitarBotaoAdicionarItem();


    return complementosInserido

  }

  AddComplementoRadio(complemento: Complemento, categoriaComplemento: CategoriaComplemento) {

    let categoriaLimpar = this.produtoSelecionado.categoriasComplemento
      .find(x => x.id == complemento.categoriaComplementoId)

    // limpa as quantidade ja inserida
    categoriaLimpar.complementos.forEach(comp => {
      categoriaComplemento.quantidadeInserida = 0;
      comp.quantidadeInserida = 0;
    })

    categoriaComplemento.quantidadeInserida++;
    complemento.quantidadeInserida++

  }

  LimparCampos() {


    //fecha modal produto selecionado 
    $(document).ready(function () {
      $('#modalItemSelecionado').modal('close');
    });

    this.observacaoItem = null;
    this.quantidadeItem = 1;

    // limpar complementos inseridos
    this.produtoSelecionado.categoriasComplemento.forEach(catcomp => {

      catcomp.quantidadeInserida = 0

      catcomp.complementos.forEach(comp => {
        comp.quantidadeInserida = 0;
      })
    })
  }

  CalcularTotalItem(): number {

    if (!this.produtoSelecionado)
      return

    return this.quantidadeItem * this.produtoSelecionado.valorVenda
      + this.quantidadeItem * this.AddComplementos().filter(item => item.complemento)
        .reduce((sum, current) => sum + current.Total(), 0);
  }


  AddQuantidadeItemComplemento(complemento: Complemento, categoriaComplemento: CategoriaComplemento) {
    if (categoriaComplemento.quantidadeInserida == categoriaComplemento.quantidadeMaxima)
      return;

    complemento.quantidadeInserida++
    categoriaComplemento.quantidadeInserida++

  }

  RemoveQuantidadeItemComplemento(complemento: Complemento, categoriaComplemento: CategoriaComplemento) {

    if (complemento.quantidadeInserida > 0) {
      categoriaComplemento.quantidadeInserida--
      complemento.quantidadeInserida--;
    }

  }

  DesabilitarBotaoAdicionarItem() {

    let todosComplementosObrigatoriosAdicionados = true;

    this.produtoSelecionado.categoriasComplemento.forEach(catComp => {

      if (catComp.quantidadeInserida < catComp.quantidadeMinima) {
        todosComplementosObrigatoriosAdicionados = false;
        return;
      }
    });
    this.botaoAdicionarItemDesabilitado = !todosComplementosObrigatoriosAdicionados;


    /*  ################# ANTIGA VALIDACAO DE COMPLEMENTOS ADICIONAIS ALTERADA NO DIA 29/01/2020 PODE SER REMOVIDA EM BREVE #########
    let categoriasObrigatorias = this.produtoSelecionado.categoriasComplemento.filter(x => x.obrigatorio == true)

    let categoriasCompletadas = this.produtoSelecionado.categoriasComplemento.filter(x => x.quantidadeInserida == x.quantidadeMinima)

    if (categoriasObrigatorias.length == categoriasCompletadas.length)
      this.botaoAdicionarItemDesabilitado = false;
    else
      this.botaoAdicionarItemDesabilitado = true;*/
  }

  showSuccess(item: ItemSacola) {
    this.toastr.success(' adicionado a sacola!', item.quantidade + 'x ' + item.produto.nome);
  }


  RespostaModalConsultaTaxa(modalAbertoConsultaTaxa) {
    this.mostrarCalculoTaxa = modalAbertoConsultaTaxa;
  }


}
