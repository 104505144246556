import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ItemSacola } from 'src/app/models/itemSacola';
import { Produto } from 'src/app/models/produto';

@Injectable({
  providedIn: 'root'
})
export class SacolaService {

  public itensSacola: ItemSacola[] = new Array;

  private itensSacolaSubject: BehaviorSubject<ItemSacola[]>;
  public itensSacolaObservable: Observable<ItemSacola[]>;


  constructor() {
    this.itensSacolaSubject = new BehaviorSubject<ItemSacola[]>(this.itensSacola)
    this.itensSacolaObservable = this.itensSacolaSubject.asObservable();
  }


  Clear() {
    this.itensSacola = [];
    this.itensSacolaSubject.next(this.itensSacola);
  }

  public AddItem(item: ItemSacola) {


    this.itensSacola.push(item);

    // let itemLocalizado = this.itensSacola.find((itemLocalizado) => itemLocalizado.produto.id == item.produto.id)

    /* if (itemLocalizado) {
       itemLocalizado.quantidade = itemLocalizado.quantidade + item.quantidade;
     }
     else {
       this.itensSacola.push(item);
     }*/

  }

  RemoveItem(item: ItemSacola) {
    this.itensSacola.forEach((itemRemover, index) => {
      if (itemRemover === item)
        this.itensSacola.splice(index, 1);
    });

    // this.itensSacola.splice(this.itensSacola.indexOf(item, 1));
  }

  SubTotal(): number {
    return this.itensSacola
      .map(item => item.Total())
      .reduce((prev, value) => prev + value, 0)
  }

}
