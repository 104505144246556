import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClienteCadastroComponent } from './cliente/cadastro/cliente-cadastro/cliente-cadastro.component';
import { CardapioComponent } from './menu/cardapio/cardapio.component';
import { SacolaComponent } from './sacola/sacola/sacola.component';
import { LoginComponent } from './login/login/login.component';
import { PedidoConcluidoComponent } from './sacola/pedido-concluido/pedido-concluido.component';
import { ContatoComponent } from './contato/contato.component';
import { AvaliacaoComponent } from './avaliacao/avaliacao.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { TestesComponent } from './testes/testes.component';
import { ErroRequestComponent } from './erro-request/erro-request.component';
import { RecuperarSenhaComponent } from './cliente/recuperacao-senha/recuperar-senha/recuperar-senha.component';
import { NovaSenhaComponent } from './cliente/recuperacao-senha/nova-senha/nova-senha.component';
import { ClienteEditarComponent } from './cliente/cadastro/cliente-editar/cliente-editar.component';

const routes: Routes = [
  
  {
    path: '',
    component: CardapioComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'cliente-cadastro',
    component: ClienteCadastroComponent
  },
  {
    path: 'cliente-cadastro/:telaChamando',
    component: ClienteCadastroComponent
  },
  {
    path: 'cliente-editar',
    component: ClienteEditarComponent
  },
  {
    path: 'recuperarsenha',
    component: RecuperarSenhaComponent
  },
  {
    path: 'novasenha',
    component: NovaSenhaComponent
  },
  {
    path: 'cardapio',
    component: CardapioComponent
  },
  {
    path: 'sacola',
    component: SacolaComponent
  },
  {
    path: 'pedido-concluido',
    component: PedidoConcluidoComponent
  },
  {
    path: 'contato',
    component: ContatoComponent
  },
  {
    path: 'avaliacao',
    component: AvaliacaoComponent
  },
  {
    path: 'pedidos',
    component: PedidosComponent
  },
  {
    path: 'testes',
    component: TestesComponent
  },

  {
    path: 'erro',
    component: ErroRequestComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
