import { Component, OnInit } from '@angular/core';

import { Empresa } from '../models/empresa';
import { EmpresaService } from '../services/empresa.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {

  empresa: Empresa;


  constructor(private empresaService: EmpresaService, 
              private authService:AuthenticationService ) { }

  ngOnInit() {

      this.empresa = this.authService.currentEmpresaValue;

  }

}
