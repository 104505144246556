import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Empresa } from '../models/empresa';
import { CrudService } from './base/crud.service';
import { URI } from '../app.api';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService extends CrudService<Empresa>{

  constructor(protected http: HttpClient) {
    super(http, 'empresa', URI);
  }

  GetEmpresaIdByNomeUrl(nomeUrl: string) {
    return this.http.get<number>(`${URI}/${this.endpoint}/getempresaidbynomeurl/${nomeUrl}`)
      .pipe(tap()
      );
  }
}
