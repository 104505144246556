import { Component, OnInit, ViewChild, ElementRef, Renderer } from '@angular/core';

import { SacolaService } from 'src/app/services/shared/sacola.service';
import { ItemSacola } from 'src/app/models/itemSacola';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Cliente } from 'src/app/models/cliente';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ClienteEndereco } from 'src/app/models/clienteEndereco';
import { EmpresaFormaPagamentoService } from 'src/app/services/empresa-forma-pagamento.service';
import { EmpresaFormaPagamento } from 'src/app/models/empresaFormaPagamento';
import { FormaPagamento } from 'src/app/models/formaPagamento';
import { Pedido, CartaoCredito } from 'src/app/models/pedido';
import { ItemPedido } from 'src/app/models/itemPedido';
import { ItemPedidoComplemento } from 'src/app/models/itemPedidoComplemento';
import { PedidoService } from 'src/app/services/pedido.service';
import { ToastrService } from 'ngx-toastr';
import { Empresa } from 'src/app/models/empresa';
import { BuscaEnderecoService } from 'src/app/services/busca-endereco.service';
import { CardBrandEnum } from 'src/app/models/enums/cardBrand';
import { PagamentoOnlineUtil } from './pagamentoOnlineUtil';

declare var $: any

@Component({
  selector: 'app-sacola',
  templateUrl: './sacola.component.html',
  styleUrls: ['./sacola.component.css']
})
export class SacolaComponent implements OnInit {

  mostrarLogin: boolean;
  mostrarModalConsultaEndereco: boolean;
  precisaDeTroco = false;
  valorTroco: number = 0;
  submitted = false;
  calculouTaxa = false;


  empresa: Empresa;
  currentUser: Cliente;
  enderecoEntrega = new ClienteEndereco;
  formaPagamentoSelecionada: FormaPagamento;
  currentUserSubscription: Subscription;
  currentEnderecoEntregaSubscription: Subscription;
  currentEmpresaSubscription: Subscription;
  itensSacola: ItemSacola[] = new Array()
  formasPagamentoDisponivel: EmpresaFormaPagamento[] = new Array();
  pedido = new Pedido();

  cartaoCredito = new CartaoCredito;
  dataValidadeCartaoCredito: string;

  constructor(private sacolaService: SacolaService,
    private authService: AuthenticationService,
    private router: Router,
    private empresaFormaPagamentoService: EmpresaFormaPagamentoService,
    private pedidoService: PedidoService,
    private toastr: ToastrService,
    private renderer: Renderer,
    private buscaEnderecoService: BuscaEnderecoService) {


    // inicializar select
    $(document).ready(function () {
      $('select').formSelect();
    });

    // inicializar modal
    $(document).ready(function () {
      $('.modal').modal({
        dismissible: false
      });
    });


    // obter usuario logado
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;

    });

  }

  ngOnInit() {
    this.currentEmpresaSubscription = this.authService.currentEmpresa.subscribe(empresa => {
      this.empresa = empresa;

    });

    // caso usuario nao esteja logado inrerir endereco de entrega armazenado no localstorage
    if (!this.currentUser) {

      // obter enderecoEntrega 
      this.currentEnderecoEntregaSubscription = this.authService.currentEnderecoEntrega.subscribe(ende => {
        this.enderecoEntrega = ende;

      });

      // calcula taxa entrega
      this.buscaEnderecoService.CalcularTaxa(this.enderecoEntrega).subscribe(taxa => {
        this.enderecoEntrega.taxa = Number(taxa);
        this.authService.setEnderecoEntrega(this.enderecoEntrega)
        this.calculouTaxa = true;
      });

    }
    else if (this.currentUser.enderecos) {

      this.enderecoEntrega = this.currentUser.enderecos.find(x => x.id > 0)
      this.enderecoEntrega.empresaId = this.authService.currentEmpresaValue.id;

      // calcula taxa entrega
      this.buscaEnderecoService.CalcularTaxa(this.enderecoEntrega).subscribe(taxa => {
        this.enderecoEntrega.taxa = Number(taxa);
        this.authService.setEnderecoEntrega(this.enderecoEntrega)
        this.calculouTaxa = true;
      });


    }

    this.ObterFormasPagamento();
    this.itensSacola = this.sacolaService.itensSacola;

  }

  ObterFormasPagamento() {

    let empresaId = this.empresa.id;

    this.empresaFormaPagamentoService.ListPorEmpresa(empresaId).subscribe(empresaFormas => {

      // ordena forma de pagamento por tipo
      this.formasPagamentoDisponivel = empresaFormas.sort((a, b) => a.formaPagamento.tipo - b.formaPagamento.tipo);
    });

  }

  Itens(): any[] {
    return this.sacolaService.itensSacola;
  }

  SubTotal(): number {
    return this.sacolaService.SubTotal();
  }

  RemoverItem(item: ItemSacola) {

    if (item.quantidade > 1) {
      item.quantidade--;
      this.toastr.info(1 + ' x ' + item.produto.nome, 'Removido');
    }
    else {
      this.sacolaService.RemoveItem(item);
      this.toastr.info(item.produto.nome, 'Removido');
    }
  }


  SelecionarFormaPagamento(formaPagamento: FormaPagamento) {

    this.formaPagamentoSelecionada = formaPagamento;
  }

  confirmarFormaPagamentoSelecionada() {

    if (this.formaPagamentoSelecionada.tipo == 0 /*pagamento online*/) {

      if (!this.cartaoCredito.numero || this.cartaoCredito.numero.length < 14) {
        this.toastr.error('Verifique o número do cartao')
        return;
      }

      if (this.cartaoCredito.bandeira == undefined) {
        this.toastr.error('Informe a bandeira')
        return;
      }

      if (this.dataValidadeCartaoCredito == null || this.dataValidadeCartaoCredito.length < 6) {
        this.toastr.error('Informe a data de válidade')
        return;
      }

      if (this.cartaoCredito.codigoSeguranca == null || this.cartaoCredito.codigoSeguranca.length < 3) {
        this.toastr.error('Informe o codigo de segurança')
        return;
      }

    }

    this.cartaoCredito.nomeBandeira = CardBrandEnum[this.cartaoCredito.bandeira]

    $('#modalFormaPagamento').modal('close');

  }

  AlterarFormaPagamento() {
    this.formaPagamentoSelecionada = null;
  }

  BuscarBandeiraCartao() {

    if (this.cartaoCredito.numero.length < 6) {
      this.cartaoCredito.bandeira = undefined;
    }

    if (this.cartaoCredito.numero.length == 6) {
      this.pedidoService.ConsultarBinCartao(this.cartaoCredito.numero, this.authService.currentEmpresaValue.id).subscribe(data => {

        this.cartaoCredito.bandeira = PagamentoOnlineUtil.retornarBandeiraCartao(data.provider);

        this.cartaoCredito.iconeHtml = data.iconeHtml
      });
    }

  }

  OnClickVoltarModalFormaPagamento() {

    this.formaPagamentoSelecionada = null;

    $('#modalFormaPagamento').modal('close');
  }

  CalcularTotalPedido() {

    let total = this.sacolaService.SubTotal();

    if (this.enderecoEntrega.taxa)
      total = this.sacolaService.SubTotal() + this.enderecoEntrega.taxa;

    return total;

  }

  RealizarLogin() {
    this.mostrarLogin = true;
  }

  FinalizarPedido() {
    this.submitted = true;

    if (this.empresa.abertaFechada == 'FECHADA') {
      this.toastr.error('Restaurante indisponivel')
      this.submitted = false;
      return;
    }

    if (this.formaPagamentoSelecionada == null) {
      this.toastr.error('Informe a forma de pagamento')
      this.submitted = false;
      return;
    }

    if (!this.calculouTaxa) {
      this.toastr.error('Taxa entrega não calculada')
      this.submitted = false;
      return;
    }



    if (!this.authService.currentUserValue) {
      this.mostrarLogin = true
      this.submitted = false;
      return;
    }


    this.pedido.clienteId = this.currentUser.id;
    this.pedido.data = new Date;
    this.pedido.empresaId = this.empresa.id
    this.pedido.enderecoEntregaId = this.enderecoEntrega.id;
    this.pedido.valorTaxaEntrega = this.enderecoEntrega.taxa;
    this.pedido.status = 0 //aguardando;
    this.pedido.valorTotalComplementos = 0;
    this.pedido.formaPagamento = this.formaPagamentoSelecionada;
    this.pedido.formaPagamentoId = this.formaPagamentoSelecionada.id;
    this.pedido.subTotal = this.sacolaService.SubTotal();
    this.pedido.valorTotal = this.CalcularTotalPedido();
    this.pedido.valorTroco = this.valorTroco
    this.pedido.motivoCancelamento = '';
    this.pedido.itens = new Array();

    if (this.formaPagamentoSelecionada.tipo == 0 /* pagamento online*/) {
      this.cartaoCredito.validade = PagamentoOnlineUtil.formatarDataValidade(this.dataValidadeCartaoCredito);
      this.pedido.CartaoCredito = this.cartaoCredito;
    }


    // adiciona item pedido
    this.itensSacola.forEach(itemSacola => {

      let itemPedido = new ItemPedido();

      itemPedido.observacao = itemSacola.observacao;
      itemPedido.quantidade = itemSacola.quantidade;
      itemPedido.valorUnitario = itemSacola.produto.valorVenda;
      itemPedido.valorTotal = itemSacola.Total();
      itemPedido.produtoId = itemSacola.produto.id;
      itemPedido.itemPedidoComplementos = new Array();

      // adiciona item pedido complemento
      itemSacola.itemSacolaComplementos.forEach(itemComplemento => {

        let itemPedidoComplemento = new ItemPedidoComplemento();
        itemPedidoComplemento.complementoId = itemComplemento.complemento.id;
        itemPedidoComplemento.quantidade = itemComplemento.quantidade;
        itemPedidoComplemento.valorUnitario = itemComplemento.complemento.valorVenda;
        itemPedidoComplemento.valorTotal = itemComplemento.Total();

        itemPedido.itemPedidoComplementos.push(itemPedidoComplemento)

      });

      this.pedido.itens.push(itemPedido);

    })

    this.pedidoService.Save(this.pedido).subscribe(() => {
      this.router.navigate(['/pedido-concluido']);
      this.sacolaService.Clear();

    }, error => {
      this.toastr.info(error.error.Message, 'Atenção');
      this.submitted = false;

    });

  }

  ConsultarEndereco() {
    this.mostrarModalConsultaEndereco = true;
  }

  RespostaModalConsultaEndereco(modalAbertoConsultaEndereco) {
    this.mostrarModalConsultaEndereco = modalAbertoConsultaEndereco;
  }

  RespostaModalLogin(modalAbertoLogin) {
    this.mostrarLogin = modalAbertoLogin;
  }

  RespostaEnderecoSelecionadoConsultaEndereco(endereco) {
    this.enderecoEntrega = endereco;
    this.enderecoEntrega.empresaId = this.authService.currentEmpresaValue.id;

    // calcula taxa entrega
    this.buscaEnderecoService.CalcularTaxa(this.enderecoEntrega).subscribe(taxa => {
      this.enderecoEntrega.taxa = Number(taxa);
      this.authService.setEnderecoEntrega(this.enderecoEntrega)
      this.calculouTaxa = true;
    });

  }

  SwitchPrecisaTrocoChange(precisaTroco: boolean) {
    this.precisaDeTroco = precisaTroco;
  }

  ValidarValorTroco(valorTroco: number) {

    if (valorTroco < this.CalcularTotalPedido())
      this.toastr.info('Valor do troco inválido! precisa ser maior de R$' + this.CalcularTotalPedido().toFixed(2))

  }

}
