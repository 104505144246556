import { Cliente } from './cliente';

export class ClienteEndereco {

    public id: number;
    public empresaId: Number;
    public cliente: Cliente;
    public clienteId: number;
    public logradouro: string;
    public numero: string;
    public bairro: string;
    public cep: string;
    public complemento: string;
    public pontoReferencia: string;
    public cidade: string;
    public uf: string;
    public observacao: string;
    public dataAlteracao: Date;
    public taxa: number;

}