import { Produto } from './produto';
import { ItemPedidoComplemento } from './itemPedidoComplemento';

export class ItemPedido {

    public id: number;
    public sequencia: number;
    public produto: Produto;
    public produtoId: number;
    public quantidade: number;
    public itemPedidoComplementos: ItemPedidoComplemento[];
    public observacao: string;
    public valorTotalComplementos: number;
    public valorUnitario: number;
    public valorTotal: number;
}