import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Cliente } from 'src/app/models/cliente';
import { ClienteService } from 'src/app/services/cliente.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-nova-senha',
  templateUrl: './nova-senha.component.html',
  styleUrls: ['./nova-senha.component.css']
})
export class NovaSenhaComponent implements OnInit {

  clienteForm: FormGroup;
  cliente: any
  mensagemSucesso = null;
  mostrarLogin = false;

  submitted = false;


  constructor(private activatedRouter: ActivatedRoute,
    private clienteService: ClienteService,
    private toastr: ToastrService) { }

  ngOnInit() {

    let clienteId = null;

    this.activatedRouter.queryParams.subscribe(params => {

      clienteId = params.cli

    });

    this.clienteService.Get(clienteId).subscribe((cliente: Cliente) => {

      this.cliente = cliente;
    },
      error => {
        console.log(error.error.Message);
      });

    this.clienteForm = new FormGroup({
      senha: new FormControl({ value: null, disabled: false }, Validators.required),
      confirmacaoSenha: new FormControl({ value: null, disabled: false }, Validators.required),
    })

  }

  get fCliente() { return this.clienteForm.controls; }

  SalvarAlteracao(cliente: any) {

    this.submitted = true;

    this.cliente.senha = cliente.senha

    this.clienteService.Save(this.cliente).subscribe(data => {

      //apos cadastro sera realizado com sucesso, o cliente sera redirecionado para a tela de login
      // this.router.navigate(['/login']);
      this.mensagemSucesso = 'Senha alterada com sucesso \n realize o login'

    }, error => {
      this.submitted = false;
      this.toastr.error(error.error.Message, 'Atenção');
    });

  }

  Login() {
    this.mostrarLogin = true
  }


  RespostaModalLogin(modalAbertoLogin) {
    this.mostrarLogin = modalAbertoLogin;
  }

}
