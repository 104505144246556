import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BuscaEnderecoService } from 'src/app/services/busca-endereco.service';
import { ClienteEndereco } from 'src/app/models/clienteEndereco';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { stringify } from '@angular/compiler/src/util';
import { isNullOrUndefined } from 'util';
import { isEmpty } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any

@Component({
  selector: 'app-calcular-taxa-entrega',
  templateUrl: './calcular-taxa-entrega.component.html',
  styleUrls: ['./calcular-taxa-entrega.component.css']
})
export class CalcularTaxaEntregaComponent implements OnInit {

  @Output() modalAberto = new EventEmitter();
  @Input() abrirModal: boolean

  submittedCalculoTaxa = false;


  cepFoiVerificado = false;
  calculouTaxa = false;

  mostrarLogin: boolean;
  erro: string;

  endereco: ClienteEndereco;

  enderecoForm: FormGroup;

  constructor(private buscaEnderecoService: BuscaEnderecoService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService) {

    // inicializar modal
    $(document).ready(function () {
      $('.modal').modal({
        dismissible: false
      });
    });

  }

  ngOnInit() {

    $(document).ready(function () {
      $('#modalBuscarEndereco').modal('open');
    });

    this.enderecoForm = new FormGroup({
      cep: new FormControl({ value: '', disabled: false }, Validators.required),
      numero: new FormControl({ value: null, disabled: false }, Validators.required),
      complemento: new FormControl({ value: null, disabled: false }),
      logradouro: new FormControl({ value: null, disabled: false }, Validators.required),
      bairro: new FormControl({ value: null, disabled: false }, Validators.required),
    });


  }

  get fEndereco() { return this.enderecoForm.controls; }


  BuscarEnderecoPeloCep(clienteEndereco: ClienteEndereco) {

    this.erro = null
    this.endereco = null
    this.calculouTaxa = false;


    if (clienteEndereco.cep.length >= 8) {


      this.spinner.show();

      clienteEndereco.empresaId = this.authService.currentEmpresaValue.id;

      this.buscaEnderecoService.BuscarEnderecoPeloCep(clienteEndereco).subscribe((endereco: ClienteEndereco) => {

        this.cepFoiVerificado = true;

        this.endereco = endereco;

        this.fEndereco.logradouro.setValue(endereco.logradouro);
        this.fEndereco.bairro.setValue(endereco.bairro);
        this.fEndereco.complemento.setValue(null);
        this.fEndereco.numero.setValue(null);


        this.spinner.hide();

      },
        error => {
          this.spinner.hide();
          this.erro = error.error.Message;
          this.endereco = null;
          this.cepFoiVerificado = false;
        });

    }
  }

  CalcularTaxa(clienteEndereco: ClienteEndereco) {


    this.submittedCalculoTaxa = true;

    clienteEndereco.empresaId = this.authService.currentEmpresaValue.id;
    clienteEndereco.cidade = this.endereco.cidade;
    clienteEndereco.uf = this.endereco.uf;

    this.buscaEnderecoService.CalcularTaxa(clienteEndereco).subscribe((valorTaxa: number) => {
      this.calculouTaxa = true;
      this.endereco.taxa = valorTaxa;

      this.submittedCalculoTaxa = false;

    },
      error => {
        this.spinner.hide();
        this.erro = error.error.Message;
        this.calculouTaxa = false;
        this.submittedCalculoTaxa = false;
      });

  }

  OnChangeInputLogradouro() {
    this.calculouTaxa = false;
    this.erro = null;
  }

  OnChangeInputBairro() {
    this.calculouTaxa = false;
    this.erro = null;

  }

  OnChangeInputNumero() {
    this.calculouTaxa = false;
    this.erro = null;

  }

  OnChangeCheckSemNumero(semNumero: boolean) {
    if (semNumero)
      this.fEndereco.numero.setValue('S/N')
    else
      this.fEndereco.numero.setValue(null)

  }


  MostrarLogin() {

    this.mostrarLogin = true
  }

  FecharModal() {
    this.modalAberto.emit(false);
  }


  ArmazenaEnderecoEntrega(endereco: ClienteEndereco) {

    endereco.taxa = this.endereco.taxa;
    this.authService.setEnderecoEntrega(endereco);
  }

  RespostaModalLogin(modalAbertoLogin) {
    this.mostrarLogin = modalAbertoLogin;
  }

}
