import { Component, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { Cliente } from '../models/cliente';
import { AuthenticationService } from '../services/authentication.service';
import { SacolaService } from '../services/shared/sacola.service';
import { ItemSacola } from '../models/itemSacola';
import { ClienteEndereco } from '../models/clienteEndereco';
import { Empresa } from '../models/empresa';
import { EmpresaService } from '../services/empresa.service';
import { map } from 'rxjs/operators';
import { AuthService } from 'angularx-social-login';

declare var $: any

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  mostrarLogin = false;
  mostrarCalculoTaxa: boolean;

  currentUser: Cliente;
  currentUserSubscription: Subscription;

  empresa: Empresa;

  currentEnderecoEntrega: ClienteEndereco;
  currentEnderecoEntregaSubscription: Subscription;

  itensNaSacolaSubscription: Subscription;
  itensNaSacola: ItemSacola[]
  valorTaxa: any = 0


  constructor(private authService: AuthenticationService,
    private sacolaService: SacolaService,
    private empresaService: EmpresaService,
    private activatedRouter: ActivatedRoute,
    private router: Router,
    private authServiceSocialLogin: AuthService) {

    // inicializacao sidenave
    $(document).ready(function () {
      $('.sidenav').sidenav();
    });

    // inicializar modal 
    $(document).ready(function () {
      $('.modal').modal({
        dismissible: false
      });
    });


    // obter usuario logado
    this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
      this.currentUser = user;

    });

    // obter enderecoEntrega
    this.currentEnderecoEntregaSubscription = this.authService.currentEnderecoEntrega.subscribe(end => {
      this.currentEnderecoEntrega = end;
    });

    // obterItensSacola
    this.itensNaSacolaSubscription = this.sacolaService.itensSacolaObservable.subscribe(itens => {
      this.itensNaSacola = itens;

    });

  }

  ngOnInit() {



    let usuarioLogado = this.authService.currentUserValue;

    this.activatedRouter.queryParams.subscribe(params => {

      let empresaId = params.emp
      let clienteId = params.cli


      // previne perder empresa reload pagina
      if (!empresaId)
        empresaId = sessionStorage.getItem('emp');

      if (empresaId) {

        // logout do usuario para caso ele comprou em outra empresa e estava armazenado no localstorage
        if (usuarioLogado)
          if (usuarioLogado.empresaId != empresaId)
            this.authService.logout();

        this.authService.setEmpresaId(empresaId);

        this.authService.currentEmpresa.subscribe(empresa => {
          this.empresa = empresa;

        });


        // nao esta mudando senha
        /*  if (!clienteId)
            this.router.navigate(['/cardapio']);*/

        this.authService.startTimer();
      }

    });

  }

  Login() {
    this.mostrarLogin = true
  }

  Logout() {
    this.authService.logout();
    this.authService.removeEnderecoEntrega();
    this.authServiceSocialLogin.signOut();
  }

  RespostaModalLogin(modalAbertoLogin) {
    this.mostrarLogin = modalAbertoLogin;
  }

  CalcularTaxaEntrega() {
    this.mostrarCalculoTaxa = true;

  }

  RespostaModalConsultaTaxa(modalAbertoConsultaTaxa) {
    this.mostrarCalculoTaxa = modalAbertoConsultaTaxa;
  }


}
