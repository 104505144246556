import { Component } from '@angular/core';

declare var $: any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'GSFood-Pedidos';

  constructor() {

    // bloquear botao voltar browser e quando clicar em voltar fechar os modal aberto
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
      $('.modal').modal('close');
    };

  }
}
