export enum CardBrandEnum {
    Undefined = 0,
    Visa = 1,
    Master = 2,
    Amex = 3,
    Elo = 4,
    Aura = 5,
    JCB = 6,
    Diners = 7,
    Discover = 8
}